export const isCypress =
  typeof window === "undefined"
    ? false
    : document.location?.pathname.startsWith("/__cypress/iframes");

export const baseURL = isCypress ? "/" : process.env.NEXT_PUBLIC_API_BASE_URL;
export const PUBLIC_TOKEN = "PUBLIC_TOKEN";
export const USER_TOKEN = "USER_TOKEN";
export const USER_UUID = "USER_UUID";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
