import axios, { AxiosResponse } from "axios";
import axiosRetry, { exponentialDelay } from "axios-retry";
import {
  extractError,
  getCookie,
  setCookie,
  base64Encode,
  CookieOptions,
  isHttps,
  getBillingTypeName,
} from "./utils";
import { baseURL, PUBLIC_TOKEN, USER_TOKEN, USER_UUID } from "./api.utils";
import { refreshAccessTokenInterceptor } from "./utils/server";

export class ApiError extends Error {
  res: AxiosResponse;
  constructor(message: string, res: AxiosResponse) {
    super(message);
    this.res = res;
  }
}

axiosRetry(axios, { retries: 3, retryDelay: exponentialDelay });

export const endpoints = {
  appointments: "/v2/appointments",
  auth: "/v2/auth",
  billing: "/v2/billing",
  client: "/v2/client",
  user: "/v2/user",
  usersV1: "/v1/users",
  userDocuments: "/v1/userdocuments",
  subscriptions: "/v2/subscription",
  calendar: "/v1/calendar",
  calendarV2: "/v2/calendar",
  documents: "/v2/documents",
  userDocumentsV2: "/v2/user/documents",
};

/**
 * Shared axios instances
 */

const commonHeaders = {
  "content-type": "application/json",
  accept: "application/json",
};
export const serviceAxios = axios.create({
  baseURL,
  headers: commonHeaders,
  validateStatus: null,
});
export const publicAxios = axios.create({
  baseURL,
  headers: commonHeaders,
  validateStatus: null,
});
export const userAxios = axios.create({
  baseURL,
  headers: commonHeaders,
  validateStatus: null,
});

/**
 * Axios interceptors
 */

publicAxios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const token = getCookie(PUBLIC_TOKEN);
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

userAxios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const token = getCookie(USER_TOKEN);
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
userAxios.interceptors.request.use(refreshAccessTokenInterceptor);

/**
 * Auth token helper methods
 */

export const setTokenCookieAndUserUuid = (jwt: string, userUUid: string) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 60);
  const cookieOptions: CookieOptions = isHttps()
    ? {
        expires,
        secure: true,
        sameSite: "None",
      }
    : {
        expires,
      };
  setCookie(USER_TOKEN, jwt, cookieOptions);
  setCookie(USER_UUID, userUUid, cookieOptions);
};

export const saveUserToken = (jwt: string) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 60);
  const cookieOptions: CookieOptions = isHttps()
    ? {
        expires,
        secure: true,
        sameSite: "None",
      }
    : {
        expires,
      };
  setCookie(USER_TOKEN, jwt, cookieOptions);
};

export const saveRefreshToken = (jwt: string) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 60);
  const cookieOptions: CookieOptions = isHttps()
    ? {
        expires,
        secure: true,
        sameSite: "None",
      }
    : {
        expires,
      };
  setCookie(USER_TOKEN, jwt, cookieOptions);
};

export const setServiceAccessToken = (token: string) => {
  serviceAxios.defaults.headers.common.authorization = `Bearer ${token}`;
};

/**
 * Service Endpoints
 */

export const getServiceToken = (
  refreshToken: string,
): Promise<{ token: string; expires_at: string }> => {
  return serviceAxios
    .post(endpoints.auth + "/token/access", undefined, {
      headers: {
        authorization: `Basic ${refreshToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("Auth failed.", e);
      return "";
    });
};

export const getPublicToken = (refreshToken: string, clientUuid: string) => {
  return serviceAxios
    .post(`${endpoints.auth}/token/public/access/${clientUuid}`, undefined, {
      headers: {
        authorization: `Basic ${refreshToken}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.error("Auth failed.", e);
      return "";
    });
};

export const getClient = (subdomain: string): Promise<Client> => {
  const url = `${endpoints.client}/clients/subdomain/${subdomain}`;
  return serviceAxios.get(url).then((res) => {
    const data = handleResponse(res);
    const client: Client = {
      uuid: data.client.uuid,
      company: data.client.company,
      currencyIso: data.client.currencyIso,
      logoUrl: data.client.logoUrl,
      timezone: data.client.timezone,
      countryIso2: data.client.countryIso2,
      title: data.client.title || null,
      country: data.client.country,
      stripeUserId: data.client.stripeUserId,
      active: !!data.client.active,
      subdomain: data.client.subdomain,
    };
    return client;
  });
};

interface GetClientSettingResult {
  page: number;
  limit: number;
  hasMore: boolean;
  total: number;
  data: {
    id: number;
    client_id: number;
    type: string;
    name: string;
    value: string;
    create_timestamp: number;
    update_timestamp: number;
    app_setting: number;
  }[];
}

export const getClientSetting = (
  clientUuid: string,
): Promise<GetClientSettingResult> => {
  const url = `${endpoints.client}/client/${clientUuid}/setting?type=pushpress-membersportal`;
  return serviceAxios.get(url).then((res) => {
    if (hasInvalidStatus(res)) {
      throw new ApiError(extractError(res), res);
    }
    return res.data;
  });
};

export const getUserProfileServer = (
  userUuid: string,
  clientUuid: string,
): Promise<User> => {
  return serviceAxios
    .get(endpoints.user + `/profiles?user_uuid=${userUuid}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return convertUserData(res.data, clientUuid);
    });
};

export const getSSOFlag = (clientUuid: string): Promise<boolean> => {
  return serviceAxios
    .get(endpoints.client + `/client/${clientUuid}/features`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return !!res.data.sso;
    });
};

export const getPlan = (
  clientId: string,
  planId: string,
  discountSlug?: string,
): Promise<Plan> => {
  const discountParam = discountSlug ? `?discount=${discountSlug}` : "";
  return serviceAxios
    .get(
      `${endpoints.subscriptions}/${clientId}/plan/${planId}${discountParam}`,
    )
    .then((res) => {
      return convertPlanData(handleResponse(res));
    });
};

export const getPlans = (
  clientId: string,
  requestFromMemberApp: boolean,
): Promise<Plan[]> => {
  const query = [];
  if (requestFromMemberApp) {
    query.push("show_on_member_portal=1");
  }

  return serviceAxios
    .get(
      `${
        endpoints.subscriptions
      }/${clientId}/plan?active=1&limit=200&${query.join("&")}`,
    )
    .then((res) => {
      return handleResponse(res).data?.map((row: any) => convertPlanData(row));
    });
};

const convertPlanData = (data: any): Plan => {
  const plan: Plan = {
    uuid: data.uuid,
    name: data.name,
    active: data.active,
    deleted: data.deleted,
    allowCheckins: data.allowCheckins,
    allowOpenGymCheckins: data.allowOpenGymCheckins,
    hasCalendarItemTypeRestriction: data.hasCalendarItemTypeRestriction,
    isCheckinWindowTiedToBillingWindow: data.isCheckinWindowTiedToBillingWindow,
    expiration: data.expiration,
    expirationType: data.expirationType,
    limitCheckins: data.limitCheckins,
    isMembership: data.isMembership,
    isTrial: data.isTrial,
    setupFee: data.setupFee,
    amount: data.amount,
    tax: data.tax || null,
    recurring: data.recurring,
    numberSessions: data.numberSessions,
    calendarItemTypes: data.calendarItemTypes,
    description: data.description || null,
    additionalInfo: data.additionalInfo || null,
    discount: data.discount || null,
    landingPageRedirectTo: data.landingPageRedirectTo,
    redirectUrl: data.redirectUrl || null,
    redirectBookingTo: data.redirectBookingTo || null,
    occurrences: data.occurrences || null,
    quantityAvailable: data.quantityAvailable,
    limitQuantity: data.limitQuantity,
    type: data.type,
    createTimestamp: data.createTimestamp,
    interval: data.interval,
    intervalType: data.intervalType,
    sessionsPerPeriod: data.sessionsPerPeriod,
    sessionsPeriod: data.sessionsPeriod,
    isPublic: data.isPublic,
    category: data.category || null,
    confirmationMessage: data.confirmationMessage,
  };
  return plan;
};

export const getAppointmentPackage = (
  clientUuid: string,
  appointmentPackageId: string,
): Promise<AppointmentPackage> => {
  return serviceAxios
    .get(
      endpoints.appointments +
        `/appointment_package/${clientUuid}/${appointmentPackageId}`,
    )
    .then((res) => {
      const data = handleResponse(res).data;
      const appointmentPackage: AppointmentPackage = {
        uuid: data.uuid,
        name: data.name,
        isFree: data.isFree,
        numberOfSessions: data.numberOfSessions,
        description: data.description,
        longDescription: data.longDescription || null,
        taxId: data.taxId,
        packagePrice: data.packagePrice,
        expirationForEachAppointmentCredit:
          data.expirationForEachAppointmentCredit,
        createdAt: data.createdAt,
        discount: data.discount || null,
        appointmentType: data.appointmentType,
        policydocuments: data.policydocuments,
      };
      return appointmentPackage;
    });
};

export const getInvoice = (invoiceUuid: string): Promise<Invoice> => {
  return serviceAxios
    .get(`${endpoints.billing}/invoice/${invoiceUuid}`)
    .then((res) => {
      const data = handleResponse(res);
      const invoice: Invoice = {
        id: data.id,
        uuid: data.uuid,
        clientId: data.clientId,
        clientUuid: data.clientUuid,
        userId: data.userId,
        userUuid: data.userUuid,
        billingId: data.billingId,
        description: data.description,
        currency: data.currency,
        paid: data.paid,
        closed: data.closed,
        closeTimestamp: data.closeTimestamp,
        firstAttemptTimestamp: data.firstAttemptTimestamp,
        nextAttemptTimestamp: data.nextAttemptTimestamp,
        lastAttemptTimestamp: data.lastAttemptTimestamp,
        isPending: data.isPending,
        deleted: data.deleted,
        locked: data.locked,
        total: data.total,
        amountPaid: data.amountPaid,
        amountRefunded: data.amountRefunded,
        attemptCount: data.attemptCount,
        attempted: data.attempted,
        appendable: data.appendable,
        comped: data.comped,
        openTimestamp: data.openTimestamp,
        cronLock: data.cronLock,
        dueTimestamp: data.dueTimestamp,
        subtotal: data.subtotal,
        discount: data.discount,
        achAttempts: data.achAttempts,
        tax: data.tax,
        shipping: data.shipping,
        requiresShipping: data.requiresShipping,
        useDefaultBilling: data.useDefaultBilling,
        forgiven: data.forgiven,
        deleteOnFail: data.deleteOnFail,
      };
      return invoice;
    });
};

export const waitInvoice = async (invoiceUuid: string, attempts: number) => {
  const timeInterval = 1000;
  const factor = 2;
  const getUserAxiosInvoice = (invoiceUuid: string) => {
    return userAxios
      .get(`${endpoints.billing}/invoice/${invoiceUuid}`)
      .then((res) => {
        if (hasInvalidStatus(res)) {
          throw new ApiError(extractError(res), res);
        }
        return res.data as Invoice;
      });
  };

  for (let index = 0; index < attempts; index++) {
    const data = await getUserAxiosInvoice(invoiceUuid);
    if (data.paid && data.closed) {
      return;
    }
    await new Promise((resolve) =>
      setTimeout(resolve, timeInterval * ((index + 1) * factor)),
    );
  }
};

export const getClientServerSettings = (
  clientId: string,
  type?: string,
  name?: string,
) => {
  const query = [];
  query.push("limit=100");
  if (type) {
    query.push(`type=${type}`);
  }
  if (name) {
    query.push(`name=${name}`);
  }
  return serviceAxios
    .get(`${endpoints.client}/client/${clientId}/setting?${query.join("&")}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.data as ClientSetting[];
    });
};

/**
 * Public Endpoints
 */

export const getAppointmentType = (
  clientUuid: string,
  appointmentTypeId: string,
): Promise<AppointmentType> => {
  return publicAxios
    .get(
      endpoints.appointments +
        `/appointment_type/${clientUuid}/${appointmentTypeId}`,
    )
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      const apptType = res.data.data;
      // url needs protocol in order to open correctly
      if (apptType.nextStepsUrl && !apptType.nextStepsUrl.startsWith("http")) {
        apptType.nextStepsUrl = `https://${apptType.nextStepsUrl}`;
      }
      return apptType;
    });
};

export const getAppointmentTypeStaff = (
  clientUuid: string,
  appointmentTypeId: string,
): Promise<AppointmentTypeStaff[]> => {
  return publicAxios
    .get(
      endpoints.appointments +
        `/appointment_type/${clientUuid}/${appointmentTypeId}/staff`,
    )
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.staff;
    });
};

export const postCreateUser = (accountData: NewAccount) => {
  const now = new Date();
  const payload = {
    ...accountData,
    parentUserId: accountData.parentUserId || 0,
    active: true,
    archived: false,
    deleted: false,
    loginAccess: 1,
    status: "active",
    userType: 100,
    assignedToUserId: 0,
    membershipStatus: JSON.stringify({
      code: "lead",
      display: "Lead",
      paused: false,
      member_since: now.toISOString().split("T")[0],
    }),
    membershipRole: "lead",
    memberSince: Math.floor(now.getTime() / 1000),
    memberPaused: false,
  };
  return publicAxios
    .post(endpoints.user + "/profile/createuser", payload)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      const data = res.data;
      const user = {
        userUuid: data.data.userUuid,
        profileUuid: data.data.uuid,
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        parentUserId: data.data.parentUserId,
      } as User;
      return user;
    });
};

export interface PostCreateLinkedAccountVariables {
  accountData: NewAccount;
  primaryAccountUserUuid: string;
  shareEmail: boolean;
}

export const postCreateLinkedAccount = ({
  accountData,
  primaryAccountUserUuid,
  shareEmail,
}: PostCreateLinkedAccountVariables) => {
  accountData.parentUserId = primaryAccountUserUuid;
  if (shareEmail) {
    accountData.shareParentEmail = 1;
    delete accountData.email;
  }
  return postCreateUser(accountData);
};

export const verifyJWT = (
  jwt: string,
): Promise<{
  userUuid: string;
  clientIds: string[];
}> => {
  return publicAxios.get(endpoints.auth + `/verify/${jwt}`).then((res) => {
    if (hasInvalidStatus(res)) {
      throw new ApiError(extractError(res), res);
    }
    return {
      userUuid: res.data.payload.permissions.user,
      clientIds: Object.keys(res.data.payload.permissions.clients),
    };
  });
};

export interface PostLoginVariables {
  username: string;
  password: string;
  clientUuid: string;
  linkToClient: boolean;
}

export const postLogin = (variables: PostLoginVariables) => {
  const { username, password, clientUuid, linkToClient } = variables;
  return publicAxios
    .post(endpoints.auth + "/login", {
      username,
      password,
      client_uuid: clientUuid,
      link_client: linkToClient,
    })
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      const user = res.data.clients.find(
        (client: any) => client.clientUuid === clientUuid,
      );
      const jwt = user.accessToken;
      setTokenCookieAndUserUuid(jwt, user.userUuid);
      user.jwt = jwt;
      return user;
    });
};

export const initiateSSO = (provider: SSOProvider) => {
  return publicAxios
    .post(endpoints.auth + "/ssostart", { provider })
    .then(handleResponse);
};

export const ssoLogin = (payload: SSOPayload): Promise<User> => {
  return publicAxios
    .post(endpoints.auth + "/ssologin", payload)
    .then(({ data }) => {
      const primaryUser = data.clients[0];
      const user: User = {
        userUuid: primaryUser.userUuid,
        profileUuid: primaryUser.profileUuid,
        firstName: primaryUser.firstName,
        lastName: primaryUser.lastName,
        dob: primaryUser.dob,
        jwt: data.accessToken,
        email: primaryUser.email,
        parentUserId: primaryUser.parentUserId,
      };
      setTokenCookieAndUserUuid(user.jwt, user.userUuid);
      return user;
    });
};

export const getDiscounts = (clientId: string): Promise<Discount[]> => {
  return publicAxios
    .get(`${endpoints.billing}/${clientId}/discount`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.data;
    });
};

export const getDiscount = (
  clientId: string,
  code: string,
): Promise<Discount> => {
  return publicAxios
    .get(`${endpoints.billing}/${clientId}/discount?code=${code}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      const discount = res.data.data.find((item: any) => item.code === code);
      if (!discount) {
        throw new Error("Discount does not exist.");
      }
      return discount;
    });
};

export const getClientSettings = (
  clientId: string,
  type?: string,
  name?: string,
) => {
  const query = [];
  query.push("limit=100");
  if (type) {
    query.push(`type=${type}`);
  }
  if (name) {
    query.push(`name=${name}`);
  }
  return publicAxios
    .get(`${endpoints.client}/client/${clientId}/setting?${query.join("&")}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.data as ClientSetting[];
    });
};

export const getServiceClientSettings = (
  clientId: string,
  type?: string,
  name?: string,
) => {
  const query = [];
  query.push("limit=100");
  if (type) {
    query.push(`type=${type}`);
  }
  if (name) {
    query.push(`name=${name}`);
  }
  return serviceAxios
    .get(`${endpoints.client}/client/${clientId}/setting?${query.join("&")}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.data as ClientSetting[];
    });
};

interface PostForgotPasswordVariables {
  clientUuid: string;
  email: string;
  redirectUrl: string;
}

export const postForgotPassword = ({
  clientUuid,
  email,
  redirectUrl,
}: PostForgotPasswordVariables) => {
  const form = new FormData();
  form.append("email", email);
  return publicAxios
    .post(
      `${endpoints.user}/profile/forgotpassword/${clientUuid}/${base64Encode(
        redirectUrl,
      )}`,
      form,
    )
    .then(handleResponse);
};

interface SignDocumentVariables {
  clientUuid: string;
  userUuid: string;
  documentUuid: string;
  body: SignDocumentBody;
}

export const patchSignDocument = ({
  clientUuid,
  userUuid,
  documentUuid,
  body,
}: SignDocumentVariables): Promise<SignDocumentResult> => {
  const formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key as keyof typeof body] as string);
  }
  return publicAxios
    .patch(
      endpoints.userDocumentsV2 +
        `/${clientUuid}/${userUuid}/${documentUuid}/signature`,
      formData,
    )
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.data;
    });
};

interface PostResetPasswordVariables {
  password: string;
  token: string;
}

export const postResetPassword = ({
  password,
  token,
}: PostResetPasswordVariables) => {
  const form = new FormData();
  form.append("password", password);
  form.append("token", token);
  return publicAxios
    .post(`${endpoints.user}/profile/resetpassword`, form)
    .then(handleResponse);
};

export const getClientTaxes = (clientUuid: string) => {
  return publicAxios
    .get(`${endpoints.client}/client/taxes/${clientUuid}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.data as ClientTax[];
    });
};

export const getClientTax = (clientUuid: string, taxUuid: string) => {
  return publicAxios
    .get(`${endpoints.billing}/${clientUuid}/tax/${taxUuid}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data as ClientTax;
    });
};

export const getClientAppointmentCredits = (
  clientUuid: string,
  userUuid: string,
) => {
  const url = `${endpoints.appointments}/appointment_credit/${clientUuid}/${userUuid}?groupBy=APPOINTMENT_TYPE`;
  return userAxios.get(url).then((res) => {
    if (hasInvalidStatus(res)) {
      throw new ApiError(extractError(res), res);
    }
    return res.data as ClientTax;
  });
};

/**
 * User Endpoints
 */
export const getUserProfile = (
  userUuid: string,
  clientUuid: string,
): Promise<User> => {
  return userAxios
    .get(endpoints.user + `/profiles?user_uuid=${userUuid}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return convertUserData(res.data, clientUuid);
    });
};

export const patchUserProfile = (
  userUuid: string,
  body: UpdatePrivacyForm,
): Promise<User> => {
  return userAxios
    .patch(`${endpoints.user}/profile/${userUuid}`, body)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data;
    });
};

type AvailableSlot = {
  startTime: string;
  endTime: string;
  displayText: string;
};
export const getAppointmentAvailability = ({
  appointmentTypeId,
  clientUuid,
  date,
  staffId = "",
  timeZone,
}: {
  appointmentTypeId: string | number;
  clientUuid: string;
  date: string;
  staffId: string;
  timeZone: string;
}): Promise<AvailableSlot[]> => {
  return userAxios
    .post(
      endpoints.appointments +
        `/appointment_type/${clientUuid}/${appointmentTypeId}/schedule?staffId=${staffId}`,
      { timeZone, date },
    )
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.slots || [];
    });
};

export const postPurchasePackage = ({
  appointmentPackageId,
  clientUuid,
  paymentId,
  userUuid,
}: {
  appointmentPackageId: string;
  clientUuid: string;
  paymentId?: string;
  userUuid: string;
}): Promise<AppointmentCredit[]> => {
  return userAxios
    .post(
      endpoints.appointments +
        `/appointment_package/${clientUuid}/${appointmentPackageId}/purchase`,
      {
        paymentId,
        userId: userUuid,
      },
    )
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      return res.data.data;
    });
};

export const postBookAppointmentSession = ({
  appointmentTypeId,
  clientUuid,
  endTime,
  userUuid,
  staffId,
  startTime,
  timeZone,
}: {
  appointmentTypeId: string | number;
  clientUuid: string;
  endTime: string;
  userUuid: string;
  staffId: string;
  startTime: string;
  timeZone: string;
}): Promise<ScheduledSession> => {
  return userAxios
    .post(endpoints.appointments + `/appointment_schedule/${clientUuid}/book`, {
      bookings: [
        {
          staffId,
          startTime,
          endTime,
          appointmentType: appointmentTypeId,
          userId: userUuid,
          startTimeZone: timeZone,
          endTimeZone: timeZone,
          eventType: "APPOINTMENT",
        },
      ],
    })
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      const booking = res.data.data[0];
      const session = {
        uuid: booking.uuid,
        userId: booking.user,
        staffId: booking.staff,
        startTime: booking.startTime,
        endTime: booking.endTime,
      };
      return session;
    });
};

type LinkedUser = User & { uuid: string };

export const getLinkedAccounts = (
  userUuid: string,
): Promise<LinkedAccount[]> => {
  return userAxios
    .get(endpoints.user + `/profiles?user_uuid=${userUuid}&linkedAccounts=1`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      const user = res.data.data[0];
      if (!user || !Array.isArray(user.linkedAccounts)) {
        return [];
      }
      return user.linkedAccounts.map((l: LinkedUser) => ({
        profileUuid: l.uuid,
        userUuid: l.userUuid,
        firstName: l.firstName,
        lastName: l.lastName,
        guardianName: l.guardianName,
        guardianEmail: l.guardianEmail,
        dob: l.dob,
        parentUserId: l.parentUserId,
      })) as LinkedAccount[];
    });
};

export const getStripeClientSecret = (
  clientUuid: string,
  userUuid: string,
): Promise<StripeOptions> => {
  return userAxios
    .post(endpoints.billing + "/billing/secret", {
      clientUuid,
      userUuid,
    })
    .then(handleResponse);
};

interface BillingMethodItem {
  uuid: string;
  accountType: "debit" | "credit" | string;
  billingLastFour: string;
  billingType: string;
}

export const getPaymentMethods = (
  userUuid: string,
): Promise<PaymentMethod[]> => {
  return userAxios
    .get(endpoints.billing + `/billing?user_uuid=${userUuid}`)
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }

      return res.data.data.map((b: BillingMethodItem) => ({
        uuid: b.uuid,
        lastFour: b.billingLastFour,
        accountType: b.accountType,
        type: getBillingTypeName(b.billingType),
      }));
    });
};

export interface SavePaymentMethodBody {
  userUuid: string;
  clientUuid: string;
  paymentMethodId: string;
  customerId: string;
}

export const postSavePaymentMethod = ({
  userUuid,
  clientUuid,
  paymentMethodId,
  customerId,
}: SavePaymentMethodBody): Promise<PaymentMethod | null> => {
  return userAxios
    .post(endpoints.billing + `/billing`, {
      userUuid,
      clientUuid,
      paymentReference: paymentMethodId,
      customerReference: customerId,
    })
    .then((res) => {
      if (hasInvalidStatus(res)) {
        throw new ApiError(extractError(res), res);
      }
      const data = res.data;
      if (!data.billing) {
        return null;
      }
      return {
        uuid: data.billing.uuid,
        lastFour: data.billing.billingLastFour,
        type: data.billing.billingType === "C" ? "card" : "bank",
      };
    });
};

export const getCalendar = (
  doy: number,
  year: number,
  calendarType?: string,
): Promise<Calendar> => {
  // doy in the backend is expecting minus one
  const adjustedDOY = doy - 1;

  const params: string[] = [];

  params.push(`doy=${adjustedDOY}`);
  params.push(`year=${year}`);
  params.push("expand=checkins,registrations");

  if (calendarType) {
    params.push(`calendar_type_id=${calendarType}`);
  }

  const query = params.length > 0 ? `?${params.join("&")}` : "";
  return userAxios.get(`${endpoints.calendar}${query}`).then(handleResponse);
};

export const getCalendarByRange = (
  startTime: number,
  endTime: number,
  calendarType?: string,
  page = 1,
): Promise<Calendar> => {
  const params: string[] = [];

  params.push(`start_time=${Math.floor(startTime / 1000)}`);
  params.push(`end_time=${Math.floor(endTime / 1000)}`);
  params.push("expand=checkins,registrations");
  params.push(`page=${page}`);

  if (calendarType) {
    params.push(`calendar_type_id=${calendarType}`);
  }
  const query = params.length > 0 ? `?${params.join("&")}` : "";
  return publicAxios.get(`${endpoints.calendar}${query}`).then(handleResponse);
};

interface PostPurchasePlanVariables {
  clientUuid: string;
  data: PurchasePlanBody;
}

export const postPurchasePlan = ({
  clientUuid,
  data,
}: PostPurchasePlanVariables): Promise<PurchasePlanResult> => {
  return userAxios
    .post(
      endpoints.subscriptions + `/${clientUuid}/subscription/purchase`,
      data,
    )
    .then(handleResponse);
};

export interface FailInvoiceData {
  onSession: boolean;
}

export const failInvoice = (
  invoiceUuid: string,
  data: FailInvoiceData,
): Promise<PurchasePlanResult> => {
  return userAxios
    .put(`${endpoints.billing}/invoice/fail/${invoiceUuid}`, data)
    .then(handleResponse);
};

export interface PostRetryInvoiceVariables {
  invoiceUuid: string;
  onSession: boolean;
}

export interface PostRetryInvoiceResult {
  wasSuccessful: boolean;
  clientSecret: string;
  stripeAccount: string;
  paymentMethod: string;
  customerName: string;
  lastFour: string;
  cardBrand: string;
  id: string;
  gateway: string;
  amount: number;
  created: number;
  currency: string;
  description: string;
  metadata: {
    amount: string;
    app: string;
    billing_customer_id: string;
    billing_id: string;
    billing_type: string;
    client_account_plan_id: string;
    client_gym_type: string;
    client_id: string;
    customer_name: string;
    end_date_1: string;
    env: string;
    invoice_id: string;
    last_charge_1: string;
    next_charge_1: string;
    off_session: string;
    paid_until_1: string;
    src: string;
    stripe_billing_customer_id: string;
    subdomain: string;
    user_id: string;
  };
  status: string;
  gatewayResponse: {
    id: string;
    object: string;
    amount: number;
    amount_capturable: number;
    amount_details: {
      tip: any;
    };
    amount_received: number;
    application: string;
    application_fee_amount: number;
    automatic_payment_methods: any;
    canceled_at: any;
    cancellation_reason: any;
    capture_method: string;
    charges: {
      object: string;
      data: Array<any>;
      has_more: boolean;
      total_count: number;
      url: string;
    };
    client_secret: string;
    confirmation_method: string;
    created: number;
    currency: string;
    customer: any;
    description: string;
    invoice: any;
    last_payment_error: any;
    livemode: boolean;
    metadata: {
      amount: string;
      app: string;
      billing_customer_id: string;
      billing_id: string;
      billing_type: string;
      client_account_plan_id: string;
      client_gym_type: string;
      client_id: string;
      customer_name: string;
      end_date_1: string;
      env: string;
      invoice_id: string;
      last_charge_1: string;
      next_charge_1: string;
      off_session: string;
      paid_until_1: string;
      src: string;
      stripe_billing_customer_id: string;
      subdomain: string;
      user_id: string;
    };
    next_action: {
      type: string;
      use_stripe_sdk: {
        source: string;
        stripe_js: string;
        type: string;
      };
    };
    on_behalf_of: any;
    payment_method: string;
    payment_method_options: {
      card: {
        installments: any;
        mandate_options: any;
        network: any;
        request_three_d_secure: string;
      };
    };
    payment_method_types: Array<string>;
    processing: any;
    receipt_email: any;
    review: any;
    setup_future_usage: string;
    shipping: any;
    source: any;
    statement_descriptor: any;
    statement_descriptor_suffix: any;
    status: string;
    transfer_data: any;
    transfer_group: any;
  };
  idempotencyKey: string;
  validated: boolean;
  confirmed: boolean;
  processAfterChargeImmediately: boolean;
}

export const postRetryInvoice = ({
  invoiceUuid,
  onSession,
}: PostRetryInvoiceVariables): Promise<PostRetryInvoiceResult> => {
  return publicAxios
    .post(`${endpoints.billing}/invoice/retry/${invoiceUuid}`, { onSession })
    .then(handleResponse);
};

export const postScheduleCalendar = (
  data: ScheduleCalendarBody,
): Promise<ScheduleCalendarResult> => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key as keyof typeof data]);
  }
  return userAxios
    .post(`${endpoints.calendar}/register`, formData)
    .then(handleResponse);
};

export const getCalendarAvailability = (
  clientId: string,
  calendarItemId: string,
  subscriptionId: string,
): Promise<ClassAvailability> => {
  return userAxios
    .get(
      `${endpoints.calendarV2}/${clientId}/item/${calendarItemId}/availability/${subscriptionId}`,
    )
    .then(handleResponse);
};

export type DocumentsFilter = {
  planId?: string;
};

export const getUnsignedDocuments = (
  userUuid?: string,
  filters?: DocumentsFilter,
): Promise<GetDocumentsResult> => {
  const params: string[] = [];

  params.push(`user_id=${userUuid}`);
  params.push("active=1");
  params.push("is_signed=0");

  const query = params.length > 0 ? `?${params.join("&")}` : "";
  return userAxios.get(endpoints.userDocuments + `${query}`).then((res) => {
    if (hasInvalidStatus(res)) {
      throw new ApiError(extractError(res), res);
    }

    let userDocuments = res.data.data;

    //  This filters to return only unsigned documents for a specific plan if needed
    //  We don't need filters to discounts, cause discount's are attached to Plans, so if we filter by plans is enough
    if (filters?.planId) {
      userDocuments = userDocuments.filter((userDocument: UserDocument) => {
        if (filters.planId && userDocument.subscription) {
          return userDocument.subscription.plan.uuid === filters.planId;
        }

        // If no plan ID is specified or the user document has no subscription, include it in the result
        return true;
      });
    }

    return {
      ...res.data,
      data: userDocuments,
    };
  });
};

export const getLocation = (locationId: string): Promise<Location> => {
  return userAxios
    .get(`${endpoints.calendarV2}/location/${locationId}`)
    .then(handleResponse);
};

export const getTax = (clientUuid: string, taxUuid: string): Promise<Tax> => {
  return userAxios
    .get(`${endpoints.billing}/tax/${clientUuid}/${taxUuid}`)
    .then(handleResponse);
};

export const getUserCountryByIp = async (): Promise<UserCountryDataByIp> => {
  try {
    const response = await fetch("https://ipapi.co/json");
    const data = await response.json();
    return {
      countryCode: data.country_code,
      countryName: data.country_name,
    };
  } catch (err) {
    console.warn("Error getting user IP information", err);

    // If cannot grab user country info by IP we can infer by navigator language
    if (typeof window !== "undefined") {
      const locale = window.navigator.language;
      const countryCode = locale.split("-")[1];
      return {
        countryCode,
      };
    }

    return {};
  }
};

const hasInvalidStatus = (res: any) => {
  if (!res.status) {
    return true;
  }
  return res.status < 200 || res.status >= 300;
};

const convertUserData = (data: any, clientUuid: string): User => {
  const user = data?.data?.find((item: any) => item.clientUuid === clientUuid);

  return {
    userUuid: user.userUuid,
    profileUuid: user.uuid,
    firstName: user.firstName,
    lastName: user.lastName,
    dob: user.dob || null,
    guardianName: user.guardianName || null,
    guardianEmail: user.guardianEmail || null,
    email: user.email,
    parentUserId: user.parentUserId,
    isMember: user.isMember,
    isLead: user.isLead,
    marketingConsent: user.marketingConsent || null,
    privacyConsent: user.privacyConsent || null,
  } as User;
};

// Utils

const handleResponse = (res: AxiosResponse) => {
  if (hasInvalidStatus(res)) {
    throw new ApiError(extractError(res), res);
  }
  return res.data;
};
